<template>
    <custom-vue-table class="pl-3 pr-3" title="Review" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                      :extra-params="{ start_date, status, end_date, rating }">
        <template #filters>
            <div class="btn-group ml-3">
                <drop-btn text="Filters" class="ml-3" icon="fa fa-filter" size="md" dropdown-width="25r">
                    <div class="p-3">
                        <validated-vue-select name="Status" :options="statusOptions"
                                              label="Status"
                                              v-model="status"/>
                        <validated-vue-select name="Rating" :options="ratingOptions"
                                              label="Rating"
                                              v-model="rating"/>
                        <validated-input type="date" label="Start Date" name="Start Date"
                                         v-model="start_date"/>
                        <validated-input type="date" label="End Date" name="End Date"
                                         v-model="end_date"/>
                    </div>
                </drop-btn>
            </div>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn color="primary" v-if="rowData.is_active" class="btn-basic-b" size="xs"
                     @click="suspendReview(rowData)" text="Suspend"/>
                <btn color="primary" v-else class="btn-basic-b" size="xs" @click="suspendReview(rowData)"
                     text="Retrieve"/>
                <btn color="primary" class="btn-basic-b" size="xs" @click="viewDetails(rowData)" text="View"/>
            </div>
        </template>
    </custom-vue-table>
</template>

<script>
import urls from '../../../../../data/urls';
import axios from 'secure-axios';

export default {
    name : 'ListingComponent',
    data () {
        return {
            start_date    : '',
            end_date      : '',
            status        : '',
            rating        : '',
            listUrl       : urls.salesAdmin.review.list,
            statusOptions : [
                { value : 'Pending', label : 'Pending' },
                { value : 'Approved', label : 'Approved' }
            ],
            ratingOptions : [
                { value : '1', label : '1 Star' },
                { value : '2', label : '2 Star' },
                { value : '3', label : '3 Star' },
                { value : '4', label : '4 Star' },
                { value : '5', label : '6 Star' }
            ],
            fields : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'product',
                    sortField : 'product',
                    title     : 'Product'
                },
                {
                    name      : 'user',
                    sortField : 'user',
                    title     : 'User'
                },
                {
                    name      : 'heading',
                    sortField : 'heading',
                    title     : 'Title'
                },
                {
                    name      : 'rating',
                    sortField : 'rating',
                    title     : 'Rating'
                },
                {
                    name      : 'is_active',
                    sortField : 'is_active',
                    title     : 'Status'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        async suspendReview (item) {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.salesAdmin.review.suspend, { id : item.id });
            const json = response.data;
            if (json.error === false) {
                that.$notify('Changed status successfully', 'Success', {
                    type : 'success'
                });
                that.loading = false;
            } else {
                that.$notify('Sorry, Please try again later', 'Failed', {
                    type : 'danger'
                });
                that.loading = false;
            }
        },
        viewDetails (rowData) {
            this.$router.push('/sales/review/' + rowData.id + '/details/');
        }
    }
};
</script>
